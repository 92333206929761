//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid';/* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'buttons';
@import 'forms';
@import 'slick';
@import 'slick-theme';
//@import 'fancybox';
//@import 'tabs';

//
//!!SITE WIDE STYLE
//

//
//!!STRUCTURE START
//

.matchHeight{
	margin-bottom: 0;
}

.lazy-loader{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: white;
	z-index: 9999;

	&.loaded{
		opacity: 0;
		display: none;
	}
}

.main-wrapper{
	padding-top: 0;
	padding-bottom: 0px;
	width: 100%;
	//min-height: calc(100vh - 91px);
}

.page-wrapper{
	margin: 0 auto;
	padding: 0;
	width: 100%;
	position: relative;
	transition: .25s;
	height: auto;
	padding-top: 0;
	bottom: 0;

//	.menu-open &{
//		transform:  translateX(-372px);
//		transition: .25s .1.5s;
//	}
}

.fp-tableCell,
.wrapper{
	margin: 0 auto;
	width: 95.834%;
	padding-bottom: 0;
	max-width: $base-width;
	position: relative;
	clear: both;

	@media (max-width: $b2){
		width: 87%;
	}

	&.mob-wide{

		@media (max-width: $b3){
			width: 100%;
		}
	}
}

.mob-wrapper{

	@media (max-width: $b3){
		margin-left: auto;
		margin-right: auto;
		width: 81%;
		padding-bottom: 0;
		max-width: $base-width;
		position: relative;
		clear: both;
		float: none;
	}
}

//
//!!STRUCTURE END
//

//
//!!SIMPLE BASE STYLES START
//

.full-bg{
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
}

//
//!!SIMPLE BASE STYLES END
//

//
//!! BREADCRUM START
//

//.breadcrumb-con{
//	font-family: $alt-font;
//	@include font-size(16);
//	letter-spacing: -.01em;
//	line-height: 1.2em;
//	margin-bottom: 54px;
//	color: $colour;
//	font-weight: 300;
//	display: flex;
//	justify-content: flex-start;
//
//	@media (max-width: $b3){
//		@include font-size(8);
//		margin-bottom: 15px;
//	}
//
//	span{
//		color: white;
//		@include font-size(16);
//		line-height: 1.2em;
//		display: inline-block;
//
//		@media (max-width: $b3){
//			@include font-size(8);
//		}
//	}
//
//	a{
//		@include font-size(16);
//		line-height: 1.2em;
//		text-decoration: none;
//		@include inline;
//		vertical-align: middle;
//		color: $white;
//		font-weight: 300;
//		margin-right: 23px;
//		position: relative;
//		display: inline-block;
//
//		@media (max-width: $b3){
//			@include font-size(8);
//			color: $orange;
//			margin-right: 12px;
//		}
//
//		&:after{
//			content: '';
//			position: absolute;
//			top: 0.8em;
//			right: -20px;
//			display: block;
//			width: 14px;
//			height: 7.927px;
//			background-image: url(../images/arrow-white.svg);
//			background-repeat: no-repeat;
//			transform: translateY(-50%);
//			background-size: 14px 7.927px;
//
//			@media (max-width: $b3){
//				background-image: url(../images/arrow-grey.svg);
//				width: 8px;
//				height: 6px;
//				top: 0.6em;
//				right: -12px;
//				background-size: 8px auto;
//				background-position: 0 50%;
//			}
//		}
//
//		&:hover,
//		&:focus{
//			color: $green;
//			text-decoration: none;
//		}
//	}
//
//	> a{
//		margin-left: 0;
//	}
//
//	.breadcrumb_last{
//		@include font-size(16);
//		line-height: 1.2em;
//		color: $green;
//		text-decoration: none;
//		@include inline;
//		vertical-align: middle;
//		font-weight: 300;
//		position: relative;
//
//		@media (max-width: $b3){
//			@include font-size(8);
//			color: $orange;
//		}
//	}
//}

//
//!! BREADCRUM END
//

//.pagination-simple{
//	padding: 7px 0 2px;
//
//	a{
//		color: $blue;
//		font-weight: 700;
//		padding-top: 1px;
//		@include inline;
//		@include font-size(20);
//		@include line-height(20,33);
//		letter-spacing: .03em;
//		margin-bottom: 1em;
//		text-transform: uppercase;
//
//		@media (max-width: $b2){
//			@include font-size(20 / $divider);
//		}
//
//		&:hover,
//		&:focus{
//			color: $blue;
//		}
//
//		&.back-to,
//		&[rel="next"]{
//			float: left;
//		}
//
//		&[rel="prev"]{
//			float: right;
//		}
//	}
//}

//simple pagination

//complex pagination

//.pagination-con{
//	display: flex;
//	justify-content: space-between;
//
//	@media (max-width: $b2){
//		justify-content: center;
//		flex-direction: column;
//		align-items: center;
//
//		.pagination.pagination.pagination{
//			margin-bottom: 20px;
//		}
//	}
//}
//
//.pagination-under{
//
//	.col-12{
//		display: flex;
//		justify-content: center;
//		align-items: center;
//
//		.pagination.pagination{
//			margin-bottom: 50px;
//		}
//	}
//}
//

// .pagination.pagination.pagination{
// 	margin-bottom: 0;
// 	vertical-align: middle;
// 	display: flex;
// 	justify-content: center;
// 	width: 100%;
// 	align-items: flex-start;
// 	flex: 0 0 100%;
// 	max-width: 100%;
// 	margin-top: -63px;

// 	@media (max-width: $b3){
// 		margin-top: 0;
// 		margin-bottom: 50px;
// 	}

// 	.pagination-mid{
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;

// 		&:before{
// 			content: 'PAGE';
// 			@include font-size(14.59);
// 			line-height: 20px;
// 			font-weight: 500;
// 			letter-spacing: .1em;
// 			color: $purple;
// 			margin-right: -2px;
// 		}
// 	}

// 	div{
// 		text-align: center;
// 		margin: 0 2px;
// 		@include font-size(14.59);
// 		line-height: 20px;


// 		&:not(.prev):not(.next){

// 			&.active{
// 				pointer-events: none;

// 				a{
// 					color: $purple;
// 				}
// 			}

// 			a{
// 				@include font-size(14.59);
// 				line-height: 20px;
// 				display: block;
// 				color: $colour;
// 				font-weight: 500;
// 				padding: 0;
// 				min-width: 20px;

// 				&:hover,
// 				&:focus{
// 					text-decoration: none;
// 					color: $purple;
// 				}
// 			}

// 			&.ellipse{
// 				color: $colour;
// 				font-weight: 500;
// 			}
// 		}

// 		&:first-of-type{
// 			margin-left: 0;
// 		}

// 		&:last-of-type{
// 			margin-right: 0;
// 		}

// 		&.next,
// 		&.prev{

// 			a{
// 				@include font-size(0);
// 				line-height: 1.2em;
// 				display: flex;
// 				justify-content: center;
// 				align-items: center;
// 				color: $colour;
// 				text-decoration: none;
// 				background-position: 50% 37%;
// 				background-repeat: no-repeat;
// 				width: 40px;
// 				height: 20px;

// 				&:hover,
// 				&:focus{
// 					text-decoration: none;
// 					color: $purple;
// 				}
// 			}
// 		}

// 		&.next{
// 			margin-left: 20px;
			

// 			a{
// 				background-image: url(../images/arrow-right-long-grey.svg);
// 				background-position: right 10px top 50%;

// 				&:hover,
// 				&:focus{
// 					background-position: right 0px top 50%;
// 				}
// 			}
// 		}

// 		&.prev{
// 			margin-right: 20px;
			

// 			a{
// 				background-image: url(../images/arrow-left-long-grey.svg);
// 				background-position: left 10px top 50%;

// 				&:hover,
// 				&:focus{
// 					background-position: left 0px top 50%;
// 				}
// 			}
// 		}
// 	}
// }

//
//!!SOCIAL MENU START
//
$social-size:		18;
$social-addition:	0;

$facebook:		#3b5999;
$twitter:		#55acee;
$linkedin:		#007bb5;
$pinterest:		#cb2027;
$rss:			#ff6600;
$slideshare:	#f7941e;
$google-plus:	#dd4b39;
$instagram:		#8a3ab9;
$reddit:		#ff4500;
$youtube:		#bb0000;
$mail:			#767676;
$behance:		#131418;

.social-menu{
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	a{
		@include font-size($social-size);
		line-height: $social-size + $social-addition + px;
		//height:  $social-size + $social-addition + px;
		min-width: $social-size + $social-addition + px;
		display: block;
		text-align: center;
		color: $black;
		text-decoration: none;
		background-image: none;
		padding: 0;
		font-weight: 100;
		margin: 0 5.5px;
		background-color: transparent;

		&:hover, &:focus{
			opacity: 1;
		}

		&:first-of-type{
			margin-left: 0;
		}

		&:last-of-type{
			margin-right: 0;
		}

		i{
			@include font-size($social-size);
			line-height: $social-size + $social-addition + px;
			min-width: $social-size + $social-addition + px;
			display: block;
			transition: $time;
		}

		&.facebook{

			&:hover,
			&:focus{
				color: $facebook!important;
			}
		}
//
//
//		&.behance{
//
//			&:hover,
//			&:focus{
//				color: $behance!important;
//			}
//		}
//
		&.twitter{

			&:hover,
			&:focus{
				color: $twitter!important;
			}
		}

		&.linkedin{

			&:hover,
			&:focus{
				color: $linkedin!important;
			}
		}
//
//		&.pinterest{
//
//			&:hover,
//			&:focus{
//				color: $pinterest!important;
//			}
//		}
//
//		&.rss{
//
//			&:hover,
//			&:focus{
//				color: $rss!important;
//			}
//		}
//
//		&.slideshare{
//
//			&:hover,
//			&:focus{
//				color: $slideshare!important;
//			}
//		}
//
//		&.google-plus{
//
//			&:hover,
//			&:focus{
//				color: $google-plus!important;
//			}
//		}
//
		&.instagram{

			&:hover,
			&:focus{

				i{
					color: $instagram!important;
				}
			}
		}
//
//		&.reddit{
//
//			&:hover,
//			&:focus{
//				color: $reddit!important;
//			}
//		}
//
//		&.youtube{
//
//			&:hover,
//			&:focus{
//				color: $youtube!important;
//			}
//		}
//
//		&.mail{
//
//			&:hover,
//			&:focus{
//				color: $mail!important;
//			}
//		}
	}
}

//
//!!SOCIAL MENU END
//

.waypoint{
	transition: .75s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(50px);
	will-change: transform;

	body.ie &{
		opacity: 1;
		visibility: visible;
		transition: 0s;
	}

	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

a{
	.hidden{
		height: 0;
		width: 0;
		overflow: hidden;
		display: block;
		opacity: 0;
		visibility: visible;
	}
}

//
//!!PAGE SPECIFIC
//

.hero-select-section{
	background-color: $green;

	.row{
		min-height: 100vh;
		align-items: center;
		padding-top: 55px;
		padding-bottom: 55px;

		@media (max-width: $b3){
			align-items: flex-start;
			flex-direction: column;
		}
	}

	*{
		color: white;
	}

	.text{
		position: relative;
		z-index: 1;
		
		@media (min-width: $b1 + 1){
			padding-right: 100px;
		}

		@media (max-width: $b3){
			padding-top: 51px;
			margin-bottom: .8em;
		}
	}

	h1{
		margin-bottom: .95em;

		@media (max-width: $b1){
			@include font-size(40);
		}

		@media (max-width: $b2){
			@include font-size(24);
		}

		@media (max-width: $b3){
			width: 90%;
		}
	}

	.links-con{
		text-align: center;
		align-self: flex-start;
		position: relative;
		z-index: 1;

		@media (min-width: $b1 + 1){
			padding-left: 28px;
		}

		@media (max-width: $b3){
			text-align: left;
			align-self: flex-start;
		}

		h3{
			font-style: italic;
			margin-bottom: .9em;

			@media (max-width: $b3){
				margin-bottom: .7em;
			}
		}

		.links{
			display: flex;
			flex-direction: column;
			align-items: center;
			transform: none;
			opacity: 1;
			visibility: visible;

			@media (max-width: $b3){
				align-items: flex-start;
				justify-content: flex-start;

				a{
					margin-bottom: .2em;
				}
			}

			@for $i from 1 through 20 {
				
				a:nth-child(1n + #{$i}) {
					transition-delay: .1 * $i + s;
				}
			}

			&.waypoint{

				a{
					transform: translateY(50px);
					opacity: 0;
					visibility: hidden;
				}

				&.animate{

					a{
						transform: translateY(0px);
						opacity: 1;
						visibility: visible;
					}
				}
			}

			a{
				@include font-size(100);
				@include line-height(100,100);
				font-family: $alt-font;
				font-weight: 400;

				@media (max-width: $b1){
					@include font-size(70);
				}

				@media (max-width: $b2){
					@include font-size(48);
				}

				@media (max-width: 388px){
					@include font-size(42);
				}
				
				&:last-child{

					&:after{
						content: '.';
					}
				}

				&:hover,
				&:focus{
					font-weight: 400;
					font-style: italic;
					color: white;
				}
			}
		}
	}

	.back-map{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		z-index: 0;

		@media (max-width: $b3){
			width: 84%;
			position: relative;
			order: 3;
			margin-top: -10em;
		}

		#mapImages{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			*{
				fill: $orange!important;
			}
		}
	}
}

@media (max-width: 388px){
	
	.links{

		.link{

			.btn{
				width: 100%;
			}
		}
	}
}

.map-hero-section{
	background-color: $grey-light;
	overflow: hidden;

	.row{
		align-items: center;
		padding-top: 111px;
		padding-bottom: 111px;
		min-height: 100vh;

		@media (max-width: $b3){
			align-items: flex-start;
			min-height: 0;
			padding-top: 83px;
			padding-bottom: 10px;
		}
	}

	h1{
		padding-top: .35em;
		@include font-size(80);
		@include line-height(80,90);

		@media (min-width: $b1 + 1){
			padding-right: 150px;
		}

		@media (max-width: $b1){
			@include font-size(50);
			@include line-height(42,50);
		}

		@media (max-width: $b3){
			width: 80%;
		}
	}

	.links{
		display: flex;
		justify-content: space-between;
		padding-top: 8.3em;

		@media (max-width: $b3){
			padding-top: 3.85em;
		}

		.link{
			width: calc(50% - 10px);

			@media (max-width: 388px){
				width: calc(50% - 5px);
			}
		}
	}

	.text{

		.links{

			@media (max-width: $b3){
				display: none;
			}
		}
	}

	.image{

		.links{

			@media (min-width: $b3 + 1){
				display: none;
			}
		}

		img,
		.video-wrapper{
			position:relative;

			video{
				position:absolute;
				top: 0;
				bottom:0;
				left: 0;
				right: 0;
				display: block;
				width: 100%;
				height: 100%;
			}
			@media (min-width: $b3 + 1){
				width: 108.5%;
				max-width: 108.5%;
			}

			@media (min-width: $b1 + 1){
				transform: translateX(-11%);
			}

			@media (max-width: $b3){
				margin-top: 23px;
				width: 95%;
			}
		}
		.video-wrapper{
			
			&::before{
				content:'';
				display: block;
				padding-top: 100%;
			}
		}
	}
}

.hero-section{
	background-color: $grey-light;

	&.no-link{

		.text{
			padding-bottom: 6.5em;

			@media (max-width: $b3){
				padding-bottom: 1.5em;
			}
		}

		h1{
			margin-bottom: 2.2em;
			padding-top: 0;
		}

		.image{
			padding-bottom: 0em;
			padding-top: 17.3em;
			justify-content: flex-start;

			@media (max-width: $b3){
				padding-top: 2.3em;
			}
		}
	}

	.row{
		align-items: center;
		padding-top: 175px;
		padding-bottom: 175px;
		min-height: 100vh;
		@media only screen and (max-width: 1024px) and (min-width: $b3 + 1){

		}
		@media (max-width: $b3){
			padding-top: 83px;
			padding-bottom: 10px;
			min-height: 0;
		}
	}

	h1{
		@include line-height(60,72);
		margin-bottom: .7em;
		padding-top: .2em;
		
		@media (min-width: $b1 + 1){
			padding-right: 150px;
		}
	}

	h2{
		@include line-height(42,50);
		margin-bottom: .9em;

		@media (min-width: $b1 + 1){
			padding-right: 300px;
		}
	}

	.link{

		h2{
			padding-right: 0;
		}
	}

	p{

		@media (min-width: $b1 + 1){
			padding-right: 300px;
		}
	}
	.text{
		position:relative;
		z-index: 2;
	}
	.links{
		display: flex;
		justify-content: space-between;
		padding-top: 1.5em;


		.link{
			width: calc(50% - 10px);

			@media (max-width: 388px){
				width: calc(50% - 5px);
			}
		}
	}

	.image{
		padding-bottom: 12.6em;
		display: flex;
		justify-content: flex-end;
		position: relative;
		z-index: 1;
		&.waypoint{

			.back{
				transition: .75s;
				opacity: 0;
				visibility: hidden;
				transform: translateY(50px);
				will-change: transform;
			}

			&.animate{

				.back{
					opacity: 1;
					visibility: visible;
					transform: translateY(0px);
					transition-delay: .25s;
				}
			}
		}

		@media (max-width: $b3){
			margin-top: 23px;
			padding-bottom: 1.6em;
		}

		img{
			position: relative;
			z-index: 2;
		}

		.back{
			position: absolute;
			bottom: -97px;
			right: 2.8%;
			z-index: 1;
			width: 120%;
			max-width: 120%;
			

			@media (max-width: $b3){
				width: 90%;
				bottom: -15px;
			}
		}
	}
}

.contact-hero-section{
	background-color: $white;

	&.no-link{

		.text{
			padding-bottom: 1.5em;
		}

		h1{
			margin-bottom: 2.2em;
			padding-top: 0;
		}
	}

	.row{
		align-items: center;
		padding-top: 111px;
		padding-bottom: 111px;
		min-height: 709px;
		align-items: baseline;
		padding-top: 165px;

		@media (max-width: $b3){
			min-height: 0px;
			padding-top: 75px;
			padding-bottom: 50px;
		}
	}

	h1{
		@include line-height(60,72);
		margin-bottom: .7em;
		padding-top: .2em;
		
		@media (min-width: $b1 + 1){
			padding-right: 150px;
		}
	}

	.links{
		display: flex;
		justify-content: space-between;
		padding-top: 3.2em;

		@media (max-width: $b3){
			padding-top: 1.2em;
		}

		.link{
			width: calc(50% - 10px);

			@media (max-width: 388px){
				width: calc(50% - 5px);
			}
		}
	}

	.contact-side{
		position: relative;

		@media (min-width: $b3 + 1){
			
			&.animate{
				transform: translateY(-20px);
			}
		}

		@media (max-width: $b3){
			margin-top: 2.3em;
		}

		.social-links{
			position: absolute;
			top: 10px;
			right: 2px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: -5px;

			@media (max-width: $b3){
				right: 10px;
				top: 0;
			}

			a{
				margin-right: 10px;
				@include font-size(24);

				&:hover,
				&:focus{
					color: $orange;

					*{
						color: $orange;
					}
				}
			}
		}

		@media (min-width: $b1 + 1){
			padding-left: 129px;
		}

		h3{
			margin-bottom: .9em;

			address,
			a{
				text-decoration: none;
				font-weight: 400;
				font-size: inherit;
				line-height: inherit;
			}

			a{

				&:hover,
				&:focus{
					color: $orange;
				}
			}
		}
	}
}

.icon-columns-section{
	background-color: $grey-light;
	padding-top: 14px;
	padding-bottom: 86px;

	@media (max-width: $b3){
		padding-top: 12px;
		padding-bottom: 78px;
	}

	.row{
		justify-content: center;
	}

	.title-con{
		text-align: center;

		h1{
			margin-bottom: 1.73em;

			@media (max-width: $b3){
				margin-bottom: 1.1em;
			}
		}
	}

	.col-lg-4{
		text-align: center;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;

		@media (max-width: $b3){
			margin-bottom: 9px;
		}

		h2{
			flex: 1;
			justify-content: center;
			display: flex;
			align-items: center;
			line-height: 1em;
			margin-bottom: .85em;

			@media (max-width: $b3){
				margin-bottom: .5em;
			}
		}

		p{
			margin-bottom: 2.35em;

			@media (max-width: $b3){
				margin-bottom: 1.6em;
			}
		}

		@media (min-width: $b3 + 1){
			padding-left: 30px;
			padding-right: 30px;
		}

		img{
			margin: 0 auto .78em;
			max-width: 290px;
			width: 100%;

			@media (max-width: $b3){
				width: 56%;
				margin: 0 auto -.6em;
			}
		}
	}
}

.find-out{
	font-family: $alt-font;
	font-style: italic;
	@include font-size(22);
	@include line-height(22,26);
	font-weight: 400;
	border-bottom: 1px solid $orange;
	display: inline-block;
	padding-bottom: 5px;

	@media (max-width: $b2){
		@include font-size(16);
		@include line-height(22,19);
		padding-bottom: 8px;
	}

	&:hover,
	&:focus{
		color: $orange;
	}
}

.orange-halfs-section{
	background-color: $orange;
	text-align: center;
	padding-top: 171px;
	padding-bottom: 171px;

	@media (max-width: $b3){
		padding-top: 64px;
		padding-bottom: 16px;
	}

	.container{
		max-width: 1475px;
	}

	.col-md-6{

		@media (max-width: $b3){
			margin-bottom: 46px;
		}

		@media (min-width: $b3 + 1){
			padding-left: 12%;
			padding-right: 12%;
			
			&:first-of-type{
				border-right: 1px solid $green;
			}

		}

		h1{
			@include line-height(60,72);
			margin-bottom: 1.08em;

			@media (max-width: $b3){
				width: 59%;
				margin-left: auto;
				margin-right: auto;
				@include line-height(26,28);
				margin-bottom: .85em;
			}
		}
	}
}

.testimonials-section{
	background-color: $green;
	text-align: center;

	&.single{

		.testimonials-slide{

			.slick-dots{
				display: none!important;
			}
		}
	}

	.slick-dots{
		bottom: -92px;

		@media (max-width: $b3){
			bottom: -37px;
		}
	}

	.slick-next{
		right: -213px;

		@media (max-width: 1664px){
			right: -53px;
		}

		@media (max-width: 1304px){
			display: none!important;
		}
	}

	.slick-prev{
		left: -213px;

		@media (max-width: 1664px){
			left: -53px;
		}

		@media (max-width: 1304px){
			display: none!important;
		}
	}

	.row{
		padding-top: 59px;
		padding-bottom: 54px;
		min-height: 593px;
		align-items: center;

		@media (max-width: $b3){
			padding-top: 60px;
			padding-bottom: 60px;
			min-height: 368px;
		}
	}

	blockquote{

		@media (max-width: $b3){
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	.container{
		max-width: 1200px;
	}

	*{
		color: $white;
	}

	.by{
		@include font-size(24);
		@include line-height(24,29);
		font-style: italic;
		font-family: $alt-font;

		@media (max-width: $b3){
			@include font-size(16);
			@include line-height(16,18);
		}
	}
}

.news-article-section + .simple-testimonial-section{

	.row{
		padding-top: 11px;
		padding-bottom: 296px;

		@media (max-width: $b3){
			padding-bottom: 75px;
		}
	}
}

.simple-testimonial-section{
	background-color: $grey-light;

	.row{
		padding-top: 33px;
		padding-bottom: 33px;
		align-items: center;
	}

	blockquote{
		@include line-height(60,72);
		margin-bottom: .82em;
	}

	.by{
		@include font-size(32);
		@include line-height(32,39);
		font-style: italic;
		font-family: $alt-font;
	}
}

.hero-section ~ .blocks-section{
	padding-top: 191px;

	@media (max-width: $b3){
		padding-top: 59px;
	}
}

.blocks-section{
	background-color: $grey-light;
	padding-top: 101px;
	padding-bottom: 107px;

	@media (max-width: $b3){
		padding-top: 59px;
		padding-bottom: 70px;
	}

	.block-slide{
		margin: 0 -10px;
		max-width: calc(100% + 10px + 10px);
		width: calc(100% + 10px + 10px);

		@media (max-width: 1240px){
			margin: 0 -20px;
			max-width: calc(100% + 20px + 20px);
			width: calc(100% + 20px + 20px);
		}

		.slick-track{
			display: flex;
		}

		.slick-slide{
			margin: 0 10px;
			height: auto;

			@media (max-width: 1240px){
				margin: 0 20px;
			}
		}
	}

	.slick-slide,
	.col-md-6{
		position: relative;
		padding-top: 126px;
		padding-left: 130px;
		padding-bottom: 68px;
		margin-bottom: 20px;

		@media (max-width: 1240px){
			padding-left: 20px;
			padding-left: 0px;
			padding-top: 66px;
			padding-bottom: 38px;
			margin-bottom: 18px;
		}

		.find-out{
			position: absolute;
			bottom: 0;
			right: 10px;

			@media (max-width:1240px){
				right: 20px;
			}
		}

		&:nth-of-type(3n + 1){

			.shape{
				background-image: url(../images/shape1.svg);
				color: $orange;
				left: 42px;
				padding-right: 29px;

				@media (max-width: 1240px){
					left: 24px;
					padding-right: 18px;
					padding-top: 6px;
				}
			}
		}

		&:nth-of-type(3n + 2){
			
			.shape{
				background-image: url(../images/shape2.svg);
				width: 251px;
				height: 203px;
				padding-top: 24px;
				padding-left: 10px;

				@media (max-width: 1240px){
					width: 177.86px;
					height: 143.39px;
					padding-left: 18px;
					top: -5px;
					left: 12px;
					padding-top: 14px;
				}
			}
		}

		&:nth-of-type(3n + 3){
			
			.shape{
				background-image: url(../images/shape3.svg);
				color: $white;
				width: 255px;
				height: 240px;
				padding-bottom: 12px;
				padding-right: 21px;
				left: 23px;

				@media (max-width: 1240px){
					width: 192px;
					height: 153px;
					top: -13px;
					left: 6px;
					padding-bottom: 17px;
					padding-right: 13px;
				}
			}
		}

		.shape{
			position: absolute;
			top: 0;
			left: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			@include font-size(32);
			@include line-height(32,39);
			font-family: $alt-font;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			width: 226px;
			height: 225px;
			z-index: 1;

			@media (max-width: 1240px){
				@include font-size(26);
				@include line-height(26,31);
				width: 161px;
				height: 160px;
				background-size: 100%;
			}
		}

		.img-con{
			padding-top: 69.7%;
			margin-bottom: .7em;

			@media (max-width:1240px){
				margin-bottom: 1.8em;
			}
		}

		h3{
			margin-bottom: 0;

			@media (max-width:1240px){
				@include font-size(26);
				@include line-height(26,30);
				width: 67%;
			}

			&.type{
				font-weight: 300;
				font-style: italic;
			}
		}
	}
}

.image-and-text-section{
	padding-top: 129px;
	padding-bottom: 214px;

	@media (max-width: $b3){
		padding-top: 104px;
		padding-bottom: 49px;
	}

	.row{
		align-items: center;
	}

	.container {
		max-width: 1358px;
	}

	.img-side{
		position: relative;

		@media (max-width: $b3){
			margin-bottom: 4.25em;
		}

		&:after{
			content: '';
			position: absolute;
			background-image: url(../images/image-back.svg);
			background-repeat: no-repeat;
			top: 0;
			left: 1%;
			right: -4%;
			max-width: 104%;
			bottom: 0;
			z-index: -1;

			@media (max-width: $b3){
				top: -27%;
				left: 7%;
				right: 11%;
				bottom: -19%;
				background-size: 100%;
			}
		}
		
		@media (min-width: $b3 + 1){
			padding-left: 82px;
			padding-top: 102px;
			padding-bottom: 77px;
		}

		@media (max-width: $b3){
			
			img{
				width: 76.8%;
				margin: 0 auto;
			}
		}
	}

	.text-side{

		@media (min-width: $b3 + 1){
			padding-left: 89px;
			padding-top: 35px;
		}

		h1{
			font-weight: 300;
			font-style: italic;
			@include line-height(60,72);
			margin-bottom: 1.5em;

			@media (max-width: $b3){
				width: 67%;
				@include line-height(26,28);
				margin-bottom: 1.15em;
			}
		}

		.text{
			padding-left: 28%;

			@media (max-width: $b3){
				padding-left: 0;
			}
		}

		p{
			margin-bottom: 1.9em;

			@media (max-width: $b3){
				margin-bottom: 1.6em;
			}
		}
	}
}

.orange-title-blocks-section{
	text-align: center;
	padding-top: 148px;
	padding-bottom: 96px;

	@media (max-width: $b3){
		padding-top: 70px;
		padding-bottom: 50px;
	}

	.title{

		h1{
			margin-bottom: 1.73em;

			@media (max-width: $b3){
				margin-bottom: 1.1em;
			}
		}
	}

	.row{
		justify-content: center;
	}

	.col-lg-4{
		display: flex;
		flex-direction: column;
		border-right: 1px solid $orange;
		margin-bottom: 5.5em;
		align-items: stretch;
		flex-wrap: wrap;

		@media (max-width: $b3){
			border-right: none;
			margin-bottom: 2.5em;
		}

		*{
			width: 100%;
		}

		&:nth-of-type(3n + 1),
		&:last-of-type{
			border-right: none;
		}

		h2{
			color: $orange;
			@include line-height(42,50);
			margin-bottom: .3em;
			min-height: 2.381em;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.text{
			padding-left: 40px;
			padding-right: 40px;
			flex: 1;
		}

		p{
			margin-bottom: 3.3em;
		}

		h3{
			font-style: italic;
			font-weight: 300;
			min-height: 3.6563em;
			margin-bottom: 0;
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

.cta-section{
	background-color: $grey-light;
	padding-top: 49px;
	padding-bottom: 104px;
	text-align: center;

	h1{
		margin-bottom: 1.1em;
	}
}

.team-section{
	padding-top: 114px;
	padding-bottom: 71px;
	text-align: center;
	background-color: $grey-light;

	@media (max-width: $b3){
		padding-top: 50px;
		padding-bottom: 0px;
	}
	.container > .row{
		width: calc(100% + 80px);
		margin-left: -40px;
		margin-right: -40px;
	}
	.col-12{
		margin-bottom: 70px;
		z-index: 2;
		position: relative;
		padding-left:40px;
		padding-right:40px;
		.img-con{

				max-width: 400px;
				margin-left:auto;
				margin-right: auto;
				img{
					max-width: 400px;
					max-height: 400px;
				}
		}
		&:nth-of-type(3n + 1){
			
			.img-con{
				&:after{
					background-image: url(../images/tb1.svg);
					top: 47.6%;
					left: 47%;
				}
			}
		}

		&:nth-of-type(3n + 2){
			
			.img-con{

				&:after{
					background-image: url(../images/tb2.svg);
					top: 38.9%;
					left: 49.4%;

					@media (max-width: $b3){
						top: 49%;
					}
				}
			}
		}

		&:nth-of-type(3n + 3){
			
			.img-con{

				&:after{
					background-image: url(../images/tb3.svg);
					top: 39.1%;
					left: 45.5%;
				}
			}
		}

		.img-con{
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 400px;
			margin-bottom: 2.55em;
			position: relative;

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				height: 100%;
				background-position: 50% 0;
				background-repeat: no-repeat;

				@media (max-width: $b3){
					width: 90%;
					height: 90%;
				}
			}
		}

		img{
			margin: 0 auto;
			position: relative;
			z-index: 1;
		}

		h3{
			margin-bottom: 2.6em;
			position: relative;

			&:after{
				content: '';
				position: absolute;
				top: calc(100% + .82em);
				height: 1.3em;
				width: 1px;
				background-color: $orange-light;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		p{
			@include font-size(19);
			@include line-height(19,28);
			font-weight: 300;
			margin: 0 auto;
			width: 400px;
		}
	}
}

.map-section{
	background-color: $grey;
	padding-top: 77px;
	padding-bottom: 38px;

	h1{
		@include line-height(60,72);
	}

	.title{
		position:relative;
		z-index: 2;
		+ .map{
			margin-top: -5.1em;
		}
	}

	.map{

		image{
			margin: 0 auto;
		}
	}
}

.main-wrapper{

	section.content-section:last-child{
		padding-bottom: 763px;
	}
}

.content-section{
	background-color: $grey-light;
	padding-top: 62px;
	padding-bottom: 49px;
	background-image: url(../images/b1.svg);
	background-repeat: no-repeat;
	background-position: 23.3% 82.3%;

	~ .content-section{
		background-image: url(../images/b2.svg);
		background-position: 50% 52.3%;
	}

	&:first-of-type{
		padding-top: 238px;
	}

	.title{
		margin-bottom: 3.4em;
	}

	h3{
		margin-bottom: 2.1em;

		@media (min-width: $b1 + 1){
			padding-right: 190px;
		}
	}

	*{

		+ h3{
			margin-top: 2.65em;
			margin-bottom: 2.5em;
		}
	}
}

.latest-news-section{
	padding-top: 216px;
	padding-bottom: 58px;
	background-color: $grey-light;

	@media (max-width: $b3){
		padding-top: 130px;
	}

	#news{

		&.loading{
			opacity: .5;
			pointer-events: none;
		}
	}

	.filter-con{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 4.65em;

		h1{
			margin-right: .95em;
			margin-bottom: 0;
		}

		.filter{
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			&.loading{
				opacity: .5;
				pointer-events: none;
			}

			div{
				margin-right: 1.7em;
				@include font-size(19);
				font-weight: 400;

				@media (max-width: $b2){
					@include font-size(16);
					margin-right: 1.2em;
				}
			}

			a{
				font-weight: 400;
				font-style: italic;
				font-family: $alt-font;
				padding: 7px 0;
				border-bottom: 1px solid $orange;
				margin-right: 1.85em;
				@include font-size(22);

				@media (max-width: $b2){
					@include font-size(18);
					margin-right: 1.2em;
				}

				&.active,
				&:hover,
				&:focus{
					color: $orange;
				}

				&.active{
					font-style: normal;
					pointer-events: none;
				}
			}
		}
	}

	.n-item{
		margin-bottom: 79px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.img-con{
				max-width: 400px;
				margin-left:auto;
				margin-right: auto;
				img{
					max-width: 400px;
					max-height: 400px;
				}
		}
		&:nth-of-type(9n + 1){
			
			.img-con:after{
				background-image: url(#{'../images/s'+random(2)+'c'+random(3)+'.svg'});
				top: random(20) + 40%;
				left: random(20) + 40%;
			}
		}

		&:nth-of-type(9n + 2){
			

			.img-con:after{
				background-image: url(#{'../images/s'+random(2)+'c'+random(3)+'.svg'});
				top: random(20) + 40%;
				left: random(20) + 40%;
			}
		}

		&:nth-of-type(9n + 3){
			

			.img-con:after{
				background-image: url(#{'../images/s'+random(2)+'c'+random(3)+'.svg'});
				top: random(20) + 40%;
				left: random(20) + 40%;
			}
		}

		&:nth-of-type(9n + 4){
			

			.img-con:after{
				background-image: url(#{'../images/s'+random(2)+'c'+random(3)+'.svg'});
				top: random(20) + 40%;
				left: random(20) + 40%;
			}
		}

		&:nth-of-type(9n + 5){
			

			.img-con:after{
				background-image: url(#{'../images/s'+random(2)+'c'+random(3)+'.svg'});
				top: random(20) + 40%;
				left: random(20) + 40%;
			}
		}

		&:nth-of-type(9n + 6){
			

			.img-con:after{
				background-image: url(#{'../images/s'+random(2)+'c'+random(3)+'.svg'});
				top: random(20) + 40%;
				left: random(20) + 40%;
			}
		}

		&:nth-of-type(9n + 7){
			

			.img-con:after{
				background-image: url(#{'../images/s'+random(2)+'c'+random(3)+'.svg'});
				top: random(20) + 40%;
				left: random(20) + 40%;
			}
		}

		&:nth-of-type(9n + 8){
			

			.img-con:after{
				background-image: url(#{'../images/s'+random(2)+'c'+random(3)+'.svg'});
				top: random(20) + 40%;
				left: random(20) + 40%;
			}
		}

		&:nth-of-type(9n + 9){
			

			.img-con:after{
				background-image: url(#{'../images/s'+random(2)+'c'+random(3)+'.svg'});
				top: random(20) + 40%;
				left: random(20) + 40%;
			}
		}

		.img-con{
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 400px;
			margin-bottom: 2.75em;
			position: relative;
			background-repeat: no-repeat;
			width: 100%;

			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				height: 100%;
				background-position: 50% 0;
				background-repeat: no-repeat;
			}
		}

		img{
			margin: 0 auto;
			position: relative;
			z-index: 1;
		}

		span{
			font-weight: 400;
			font-style: italic;
			font-family: $alt-font;
			padding: 8px 0;
			border-bottom: 1px solid $orange;
			margin-right: 1.85em;
			@include font-size(22);
			display: inline-block;
			margin-bottom: .66em;
		}

		h3{
			margin-bottom: 1.3em;
			@include line-height(32,39);
			flex: 1;

			a{
				text-decoration: none;

				&:hover,
				&:focus{
					color: $orange;
				}
			}
		}

		p{
			@include font-size(19);
			@include line-height(19,28);
			font-weight: 300;
			margin-bottom: 1.25em;

			@media (min-width: $b2 + 1){
				padding-right: 50px;
			}
		}

		.date{
			font-weight: 400;
			margin-bottom: 1.6em;
		}
	}
}

.header-section{
	padding-top: 217px;
	background-color: $grey-light;

	@media (max-width: $b3){
		padding-top: 104px;
	}

	.row{
		align-items: center;
		position: relative;
		z-index: 1;

		@media (max-width: $b3){
			align-items: flex-start;
		}
	}

	@media (max-width: $b3){
		
		.type{
			display: none;
		}

		.date{
			display: none;
		}
	}

	span{
		font-weight: 400;
		font-style: italic;
		font-family: $alt-font;
		padding: 8px 0;
		border-bottom: 1px solid $orange;
		@include font-size(22);
		display: inline-block;
	}

	.date{
		padding: 9px 0 6px;
	}

	.title{
		margin-top: 2.65em;

		@media (max-width: $b3){
			margin-top: 0;
		}

		h1{
			@include line-height(60,72);
		}
	}
}

.gallery-section{
	padding-top: 64px;
	padding-bottom: 10px;
	background-color: $grey-light;

	@media (max-width: $b3){
		padding-top: 42px;
		padding-bottom: 23px;
	}

	.row{
		position: relative;

		&:before{
			content: '';
			position: absolute;
			top: -31%;
			left: 0.5%;
			width: 60%;
			padding-top: 48.8%;
			background-image: url(../images/bg1.svg);
			background-repeat: no-repeat;
			background-size: 100% auto;
		}
	}

	&.single{
		padding-bottom: 64px;

		@media (max-width: $b3){
			padding-bottom: 23px;
		}

		.slick-dots{
			display: none!important;
		}
	}


	.slick-dots{
		left: 41px;
		bottom: 24px;
		transform: none;
	}

	.slick-dots li button{
		background-color: $white;

		&:hover,
		&:focus{
			background-color: $orange;
		}
	}

	.slick-dots li.slick-active button{
		background-color: $orange;
	}
}

.news-article-section{
	padding-top: 61px;
	padding-bottom: 12px;
	background-color: $grey-light;

	.row{
		position: relative;
		z-index: 2;
	}

	@media (max-width: $b3){
		padding-top: 60px;
	}

	&:last-child{
		padding-bottom: 198px!important;
	}

	h3{
		margin-bottom: 1.72em;

		@media (max-width: $b2){
			@include font-size(22);
			@include line-height(22,26);
			padding-right: 20px;
			margin-bottom: 1.65em;
		}

		@media (min-width: $b1 + 1){
			padding-right: 250px;
		}
	}

	h1{
		margin-bottom: .94em;

		@media (max-width: $b3){
			margin-bottom: 1.5em;
		}

		~ h1{
			margin-top: 1.85em;
		}
	}

	h4{
		margin-bottom: .6em;

		@media (max-width: $b2){
			@include font-size(22);
			@include line-height(22,22);
			margin-bottom: 1.15em;
		}
	}

	p{
		margin-bottom: 2.55em;

		@media (min-width: $b1 + 1){
			padding-right: 50px;
		}

		@media (max-width: $b3){
			margin-bottom: 2.1em;
			padding-right: 10px;
		}
	}

	.spacer{
		position: relative;

		@media (max-width: $b3){
			order: 2;
		}

		.side{
			top: 50px;
			position: sticky;
			
			@media (min-width: $b3 + 1){
				width: 413px;
			}

			img{
				width: 100%;
				margin-bottom: 1.2em;
			}

			h3{
				padding-right: 50px;
				font-style: italic;
				margin-bottom: 0;
				padding-bottom: 1.43436em;

				@media (max-width: $b3){
					padding-right: 25px;
				}
			}
		}
	}

	~ .news-article-section{
		padding-top: 24px;
		padding-bottom: 198px;

		@media (max-width: $b3){
			padding-bottom: 84px;
		}

		.row{
			position: relative;
			z-index: 1;
	
			&:before{
				content: '';
				position: absolute;
				top: -143%;
				left: 0.9%;
				width: 98.2%;
				padding-top: 79.9%;
				background-image: url(../images/bg2.svg);
				background-repeat: no-repeat;
				background-size: 100% auto;
			}
		}
	}
}

.stand-out-text-section{
	background-color: $grey-light;

	h1{
		@include line-height(60,72);
	}

	.row{
		position: relative;
		z-index: 2;
	}
}

#contactForm{
	position: fixed;
	width: 960px;
	top: 0;
	left: 0%;
	height: 100vh;
	background-color: $green;
	z-index: 111;
	padding: 64px 71px 10px;
	transform: translateX(-100%);
	transition: $time;

	.inner{
		overflow: auto;
		max-height: calc(100vh - 64px - 10px);
	}

	&.open{
		transform: translateX(0%);
	}

	h1{
		@include line-height(60,72);
		margin-bottom: .65em;

		@media (min-width: $b1 + 1){
			padding-right: 250px;
		}
	}

	p{
		margin-bottom: 1.6em;

		@media (min-width: $b1 + 1){
			padding-right: 460px;
		}
	}

	*{
		color: white;
	}

	.wpcf7{
		width: 408px;
	}

	.close{
		position: absolute;
		top: 55px;
		right: 50px;
		width: 35px;
		height: 35px;

		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(-45deg);
			width: 35px;
			height: 1px;
			background-color: $white;
		}

		&:after{
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
}

.image-banner{
	position:relative;

	// &::before{
	// 	content:'';
	// 	display: block;
	// 	position:absolute;
	// 	top: 0;
	// 	left: 0;
	// 	right: 0;
	// 	bottom: 0;
	// 	background-color:rgba($black, .5);
	// 	z-index: 2;
	// 	display: none;
	// }
}

@media (max-width: 1453px){
	
	.container{
		padding-right: 20px;
		padding-left: 20px;
	}

	.row{
		margin-right: -10px;
		margin-left: -10px;
	}
}

@media (max-width: $b3){
	
	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: 20px;
		padding-left: 20px;
	}

	.row{
		margin-right: -20px;
		margin-left: -20px;
	}
}

.four-zero-four{
	padding-top: 150px;
	padding-bottom: 150px;
	text-align: center;
}