  //!!FORM
::-webkit-input-placeholder {
	@include font-size(19);
	color: $white
}

:-moz-placeholder { /* Firefox 18- */
	@include font-size(19);
	color: $white
}
 
::-moz-placeholder {  /* Firefox 19+ */
	@include font-size(19);
	color: $white
}

:-ms-input-placeholder {
	@include font-size(19);
	color: $white
}

form{
	margin: 0 auto;
	width: auto;
	display: block;
	margin-bottom: 0;

	&:after{
		visibility:hidden;
		display:block;
		font-size:0;
		content:" ";
		clear:both;
		height:0;
	}

	* html &{
		height:1%;
	}
}

$input-bottom-margin: 7px;

input,
input.input-text,
textarea,
select{
	display: block;
	padding: 21px 24px;
	@include font-size(19);
	font-weight: 400;
	color: $white;
	font-family: $main-font;
	width: 100%;
	max-width: 100%;
	margin-bottom: $input-bottom-margin;
	border: 1px solid $white;
	background-color: transparent;
	border-radius: 0;
	line-height: 1em;
	-webkit-appearance: none;
	box-shadow: none;
}

textarea{
	height: 160px;
	min-height: 160px;
	min-width: 100%;
}

input:focus,
input.input-text:focus,
textarea:focus,
select:focus{
	outline: none;
}

input:focus,
input.input-text:focus,
textarea:focus,
select:focus{
	outline: none;
	transition: color 0.5s ease, background-color 0.5s ease;
}

[type="file"]{
	border: none;
	padding: 0;
}

//
//!!SELECT START
//
select{
	padding-right: 39px;
	appearance: none;
	@include svg_img("../images/select-icon");
	background-position: 100% 50%;
	line-height: 39px;
	height: 39px;
	overflow: hidden;
	padding-top: 0;
	padding-bottom: 0;
	cursor: pointer;

	&::-ms-expand {
		display: none;
	}

	option{
		padding-top: 5px;
		padding-bottom: 5px;
		color: $colour;
	}
}
//
//!!SELECT END
//

label{
	display: block;
	@include font-size(19);
	font-family: $main-font;
	margin-bottom: 3px;
	transition: $time;
	font-weight: 600;
	color: $white;

	.gfield_required{
		display: none;
	}

	+ .small{
		margin-top: -21px;
		margin-bottom: 1.655em;
	}
}

*[type="submit"],
.submit-con [type="submit"]{
	@extend .btn;
	margin-top: 1em;
	width: auto;

	@media (max-width: $b3){
		margin-top: .5em;
	}
}

.submit-con{
	display: flex;
	justify-content: center;
}

//
//!!RADIO & CHECKBOX START
//

.input-radio,
.input-checkbox{
	position: relative;
	@include inline;
	margin-bottom: 5px;

	&:before{
		content: '';
		display: block;
		position: absolute;
	}
}

.small{
	@include font-size(15.67);
	margin-top: -8px;
	margin-bottom: 3.9em;
}

input[type="radio"],
input[type="checkbox"]{
	display: none;

	& + span,
	& + .wpcf7-list-item-label,
	& + label{
		padding-left: 39px!important;
		position: relative;
		cursor: pointer;
		line-height: 26px;
		@include inline;
		margin-bottom: 3px;
		@include font-size(19);
		font-style: normal;
		color: white;
		font-weight: 300;
		text-transform: none;

		&:before{
			position: absolute;
			content: "";
			width: 26px;
			height: 26px;
			left: 0;
			border-radius: 0px;
			background-position: 0% 50%;
			border: 1px solid white;
			font-weight: 400;
		}

		&:after{
			position: absolute;
			content: "";
			width: 24px;
			height: 24px;
			line-height: 24px;
			text-align: center;
			font-weight: 900;
			left: 1px;
			top: 1px;
			background-color: $orange;
			opacity: 0;
			transition: $time;
			border-radius: 0;		
		}
	}

	&:checked + label:after,
	&:checked + .wpcf7-list-item-label:after,
	&:checked + span:after,
	& + label.checked:after{
		opacity: 1;
	}
}

input[type="radio"]{

	& + .wpcf7-list-item-label,
	& + label{

		&:before,
		&:after{
			border-radius: 18px;
		}
	}
}

//
//!!RADIO & CHECKBOX END
//


//
//!!CONTACT FORM 7 START
//
.send-con{
	display: flex;
	justify-content: flex-end;
	padding: 3px 0 0;
}

.wpcf7{
	position: relative;

	.col{
		position: static;
	}

	.your-consent{
		padding-top: 14px;
	}

	form{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 1px 0 1px;

		[type="submit"]{

			@media (max-width: $b3){
				margin-top: 0;
			}

			&.space-above{
				margin-top: 76px;

				@media (max-width: $b3){
					margin-top: -11px;
				}
			}
		}

		.field-p{
			margin-bottom: 0;
		}

		&.sent{

			.wpcf7-list-item{

				label{
					opacity: 1;
				}
			}

			label,
			.wpcf7-form-control-wrap,
			input[type="submit"],
			button,
			.num-button{
				pointer-events: none;
				opacity: .2;
				transition: .4s;
				display: none;
			}
		}

		label{

			span{
				color: $red;
			}
		}

		.wpcf7-form-control-wrap{
			display: block;
			width: 100%;
		}

		> div{
			width: 100%;
		}

		.form-text{
			display: flex;
			flex-direction: column;
			text-align: center;
			align-items: center;
		}

		.small,
		.medium,
		.large{
			position: relative;
		}

		.small{
			width: 32%;
			width: calc(33.333% - 13px);

			@media (max-width: $b3){
				width: 48%;
				width: calc(50% - 13px);


				+ .small + .small{
					width: 100%;
				}
			}

			@media (max-width: $b4){
				width: 100%;
			}
		}

		.medium{
			width: 48%;
			width: calc(50% - 13px);

			@media (max-width: $b3){
				width: 100%;
			}
		}

		.large{
			width: 100%;
		}

		.has-label{
			display: flex;
			align-items: baseline;

			label{
				width: 100px;
			}

			.wpcf7-form-control-wrap{
				width: calc(100% - 100px);
			}
		}

		.btn-con{
			position: static;
			justify-content: center;
		}

		span.wpcf7-not-valid-tip{
			position: absolute;
			background-color: transparent;
			color: $red!important;
			@include font-size(12);
			font-weight: 400;
			line-height: 14px;
			width: auto;
			bottom: -14px;
			transition: $time;
			left: 0px;
			display: none;

			@media (max-width: $b1){
				@include font-size(10);
				bottom: -12px;
			}
		}

		.wpcf7-checkbox,
		.wpcf7-radio{
			display: flex;
			flex-wrap: wrap;
			margin-bottom: $input-bottom-margin;
			margin-bottom: 40px;
			margin-top: 40px;

			@media (max-width: $b3){
				margin-bottom: 20px;
				margin-top: 20px;
			}

			label{
				margin-bottom: 5px;
			}

			.wpcf7-list-item-label{
				margin-right: 32px;
			}
		}

		.wpcf7-list-item{
			display: block;
			margin: 0;

			label{
				text-align: left;
			}
		}
	}

	//ajax load and loading animation
	span.ajax-loader.ajax-loader{
		position: absolute;
		top: -15px;
		left: -15px;
		height: calc(100% + 30px);
		width: calc(100% + 30px);
		max-width: calc(100% + 30px);
		background-image: none;
		background-color: rgba(white, .75);
		margin: 0;
		border-radius: 0;

		//visibility: visible;

		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 20px;
			height: 20px;
			animation-name: loading-op;
			animation-iteration-count: infinite;
			animation-duration: 3.6s;
			transform-origin: 50% 50%;
			animation-timing-function: linear;
			border: 2px solid $red;
			border-radius: 0;

			@keyframes loading-op{
				0%,
				100%{
					transform: translate(calc(-50% - -10px), calc(-50% - -10px));
				}

				25%{
					transform: translate(calc(-50% - 10px), calc(-50% - -10px));
				}

				50%{
					transform: translate(calc(-50% - 10px), calc(-50% - 10px));
				}

				75%{
					transform: translate(calc(-50% - -10px), calc(-50% - 10px));
				}
			}
		}

		&:after{
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 20px;
			height: 20px;
			animation-name: loading;
			animation-iteration-count: infinite;
			animation-duration: 3.6s;
			transform-origin: 50% 50%;
			animation-timing-function: linear;
			border: 2px solid $red;

			@keyframes loading{
				0%,
				100%{
					transform: translate(calc(-50% - 10px), calc(-50% - 10px));
				}

				25%{
					transform: translate(calc(-50% - -10px), calc(-50% - 10px));
				}

				50%{
					transform: translate(calc(-50% - -10px), calc(-50% - -10px));
				}

				75%{
					transform: translate(calc(-50% - 10px), calc(-50% - -10px));
				}
			}
		}
	}

	div.wpcf7-response-output.wpcf7-response-output.wpcf7-response-output,
	div.wpcf7-mail-sent-ok,
	div.wpcf7-validation-errors,
	div.wpcf7-acceptance-missing{
		margin: .5em 0 .5em;
		border: 1px solid $red;
		background-color: white;
		color: $red!important;
		text-align: center;
		@include font-size(14);
		line-height: 1.4em;
		padding: 5px 5px;
		border-radius: 0;
		font-weight: 400;
	}

	.sent{

		div.wpcf7-response-output.wpcf7-response-output.wpcf7-response-output{
			color: $green!important;
			border-color: $green;
		}
	}

	div.wpcf7-mail-sent-ok.wpcf7-mail-sent-ok{
		background-color: $green;
		border-color: $green;
	}

	.LV_validation_message{
		display: none;
	}
}

.re ~ .res{
	display: none;
}

//
//!!CONTACT FORM 7 END
//
