html{
	font-size: 62.5%;
	font-family: $main-font;
	font-weight: 300;
	margin-top: 0 !important;
	visibility: visible;
	overflow-x: hidden;

	&.menu-open{
		overflow: hidden;
	}

	// &.loaded{
	// 	//opacity: 1;
	// 	//visibility: visible;

	// 	body{
	// 		//overflow-y: visible;
	// 		//overflow: hidden;
	// 	}
	// }

	// @media (max-width: 1024px){

	// 	&.search-open,
	// 	&.menu-open{
	// 		overflow: hidden;

	// 		body{
	// 			overflow: hidden;
	// 		}
	// 	}
	// }

	// &#backtotop{
	// 	margin-top: 0!important;
	// }
}

body{
	color: $colour;
	background-color: $main-bg-colour;
	@include font-size(18);
	@include line-height(18,24);
	line-height: 1.4em;
	font-weight: 300;
	position: relative;

	&.home{
		background-color: $green;
	}

	@media (max-width: 1024px){
		@include font-size(16);
		@include line-height(16,22);
	}

}

#wpadminbar{
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
blockquote,
pre,
table,
li{
	color: $colour;
	margin-top: 0;
	font-weight: 300;
	margin-bottom: 1.05em;
	@include font-size(18);
	@include line-height(18,24);
	line-height: 1.4em;

	@media (max-width: 1024px){
		@include font-size(16);
		@include line-height(16,22);
	}

	sub, 
	sup{
		font-size: 60%;
	}

	a{
		font-size: inherit;
		text-decoration: underline;

		&:hover{
			color: $green-light;
			text-decoration: underline;
		}
	}

	strong{
		font-weight: 600;
		color: inherit;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
blockquote{
	color: $colour;
	font-family: $alt-font;
	margin-bottom: .45em;
	font-weight: 400;

	em{
		font-weight: 300;
	}
}

h1{
	@include font-size(60);
	@include line-height(60,60);

	@media (max-width: $b1){
		@include font-size(40);
	}

	@media (max-width: $b2){
		@include font-size(26);
	}
}

h2{
	@include font-size(42);
	@include line-height(42,46);
	margin-bottom: .75em;

	@media (max-width: $b1){
		@include font-size(32);
	}

	@media (max-width: $b2){
		@include font-size(26);
		margin-bottom: .61em;
	}

	@media (max-width: 388px){
		@include font-size(23);
	}
}

h3{
	@include font-size(32);
	@include line-height(32,39);

	@media (max-width: $b1){
		@include font-size(26);
	}

	@media (max-width: $b2){
		@include font-size(18);
	}
}

h4{
	@include font-size(32);
	@include line-height(32,39);
	font-weight: 500;

	@media (max-width: $b1){
		@include font-size(26);
	}

	@media (max-width: $b2){
		@include font-size(18);
	}
}

h5{
	@include font-size(24);
	@include line-height(24,29);
}

h6{
	@include font-size(22);
	@include line-height(22,26);
}


p{
	@include font-size(18);
	@include line-height(18,24);
	line-height: 1.4em;

	@media (max-width: 1024px){
		@include font-size(16);
		@include line-height(16,22);
	}
}


strong{
	font-weight: 700;
}

blockquote{
	@include font-size(60);
	@include line-height(60,68);
	letter-spacing: normal;
	font-family: $alt-font;
	margin: 0 auto .85em;
	font-weight: 400;
	position: relative;
	padding: 0;
	font-style: normal;

	@media (max-width: 1024px){
		@include font-size(43);
		@include line-height(26,30);
		margin: 0 auto 1.05em;
	}
	@media (max-width: $b2){
		@include font-size(26);
	}

	p{
		font-size: inherit;
		line-height: inherit;
		font-family: $main-font;
		margin-bottom: 0!important;
	}
}

a{
	font-size: inherit;
	color: $colour;
	font-weight: 300;
	transition: $time;
	cursor: pointer;
	text-decoration: none;

	&:hover{
		color: $green-light;
		text-decoration: none;
	}
}

hr{
	border: 0;
	width: 214px;
	height: 1px;
	background-color: $green;
	margin: 0 auto 1em;
}

address{
	font-style: normal;
	@include font-size(18);
	@include line-height(18,24);
	color: $colour;
	font-weight: 300;
	margin-bottom: 1.4em;

	@media (max-width: 1024px){
		@include font-size(16);
		@include line-height(16,22);
	}
}

ol,
ul,
dl{
	margin: 0;
	padding-left: 0;
	list-style-position: inside;
	@include font-size(0);
	margin-bottom: 19.95px;

	li,
	dt,
	dd{
		@include font-size(18);
		@include line-height(18,24);
		margin-bottom: 0;

		@media (max-width: 1024px){
			@include font-size(16);
			@include line-height(16,22);
		}

		a{
			font-size: inherit;

			&:hover{
				text-decoration: underline;
			}
		}
	}

	dd{
		margin-bottom: 20px;
	}
}

ul{
	list-style-position: inside;
	padding-left: 0;

	&.no-bullet{
		list-style: none;

		li{
			padding-left: 0;

			&:before{
				display: none;
			}
		}
	}

	ul{
		margin-top: 0.8em;

		li{

			&:before{
				background-color: transparent;
			}
		}
	}

	li{
		padding-left: 53px;
		list-style: none;
		position: relative;
		margin-bottom: .65em;

		@media (max-width: 1024px){
			padding-left: 38px;
		}

		&:before{
			content: '';
			position: absolute;
			left: 0;
			top: .65em;
			width: 33px;
			height: 1px;
			border-radius: 24px;
			display: inline-block;
			background-color: $colour;

			@media (max-width: 1024px){
				width: 26px;
			}
		}
	}
}

ul.small-menu-mob,
ul.footer-small-menu,
ul.small-menu,
ul.post-categories,
ul.tabs,
ul.main-menu,
ul.info-menu,
ul.footer-menu,
ul.sub-menu,
ul.gform_fields,
ul.slick-dots,
ul.social-menu,
ul.flex,
ul.slick,
ul.aside-menu,
ul.aside-menu ul{
	list-style: none;
	padding-left: 0;
	padding-top: 0;

	> li{
		padding-left: 0;
		margin-bottom: 0;

		&:before,
		&:after{
			display: none;
		}
	}
}

ol:not(.pagination){
	list-style-position: outside;
	padding-left: 0px;
	counter-reset: section;

	li{
		padding-left: 23px;
		list-style: none;
		position: relative;

		&:before{
			counter-increment: section;
			content: counter(section)".";
			position: absolute;
			left: 0;
		}
	}
}

table{
	width: 100%;
	background-color: transparent;
}

table,
table tr,
table th,
table td{
	border: none;
	border-collapse: collapse;
	padding: 12.5px 10px 12.5px 0;
	@include font-size(18);
	@include line-height(18,24);
	vertical-align: top;
	text-align: left;
	font-weight: 300;
	color: $colour;

	@media (max-width: 1024px){
		@include font-size(16);
		@include line-height(16,22);
	}

	tr{
		height: auto!important;
		padding: 0;
	}

	th{
		padding: 18px 10px 18px 0;
		font-weight: 500;

		&:first-of-type{
			width: 19%!important;
		}

		&:last-of-type{
			width: 11.5%!important;
		}
	}

	th,
	td{
		width: auto!important;
		height: auto!important;

		&:last-of-type{
			padding-right: 0;
		}
	}
}

table{
	margin-bottom: 2.05em;

	@media (max-width: $b1){
		margin-bottom: 1.7em;
	}

	tbody tr{
		border-top: 1px solid $colour;
	}
}

.wp-caption{

	.wp-caption-text{
		@include font-size(18);

		&:last-of-type:last-of-type{
			margin-bottom: 0;
			padding-top: 14px;
		}
	}
}

svg,
p.p-img a,
.wp-caption,
video,
img{
	max-width: 100%;
	width: auto;
	height: auto;
	display: block;

	&.alignnone{
		margin: 0 0 .7em;
	}

	&.alignright {
		float:right;
		margin: 0 0 .7em 3.9em;
	}

	&.alignleft {
		float:left;
		margin: 0 3.9em .7em 0;
	}

	&.aligncenter {
		clear: both;
		display: block;
		margin: 0 auto .7em;
	}
}

a img.alignright {
	float:right;
	margin: 0 0 .7em 3.9em;
}

a img.alignleft {
	float:left;
	margin: 0 3.9em .7em 0;
}

a img.aligncenter {
	clear: both;
	display: block;
	margin: 0 auto .7em;
}

::selection {
	background: darken($orange, 2%);
	color: white;
}

::-moz-selection {
	background: darken($orange, 2%);
	color: white;
}

.fieldset{
	margin-bottom: 0;

	.field{
		position: relative;
		padding-bottom: 1px;
	}

	div.mage-error[generated]{
		position: absolute;
		bottom: 5px;
	}
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus,
input:focus,
textarea:focus,
select:focus{
	border-color: white!important;
}

.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus,
input.mage-error:focus,
select.mage-error:focus,
textarea.mage-error:focus,
.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid,
li.gfield.error input,
.gfield_error input,
.gfield_error select,
.gfield_error .dk-select,
.gfield_error textarea,
input.mage-error,
select.mage-error,
textarea.mage-error{
	border-color: $red!important;
}

div.mage-error[generated] {
	color: $red;
	position: absolute;
	margin-top: 4px;
	line-height: 1.1em;
	padding-top: 2px;
	padding-left: 14px;
	@include svg_img("../images/error-arrow");
	background-position: 0 0;
	margin-left: -2px;
	text-align: left;
}

.browsehappy{
	position: fixed;
	top: 0;
	left: 50%;
	z-index: 999999999;
	background-color: white;
	margin-bottom: 0;
	width: 540px;
	margin-left: -270px;
	text-align: center;
	padding: 4px 2px;
	font-size: 13px;
	color: black;
	border-radius: 0 0 4px 4px;
	line-height: 13px;
	font-weight: 300;

	span{
		background-image: url(../images/browserhappy-cross.png);
		background-repeat: no-repeat;
		height: 13px;
		width: 13px;
		@include inline;
		vertical-align: middle;
		background-position: 50% 50%;

		&:hover{
			background-color: #f96059;
			transition: $time;
		}
	}

	strong{
		font-weight: 700;
	}

	a{
		color: $green-light;
		text-decoration: none;
		font-weight: 300;

		&:hover{
			color: $green-light;
			text-decoration: underline;
		}
	}
}

.ta{
	display: table;
	width: 100%;
	height: 1px;
}

.tc{
	display: table-cell;
	vertical-align: middle;
}

.waypoint{
	transition: .4s;
}

.flex{
	display: flex;
	flex-wrap: wrap;

	&.center{
		align-items: center;
		justify-content: center;
	}

	&.top{
		align-self: flex-start;
	}

	&.bottom{
		align-self: flex-end;
	}

	&.no-wrap{
		flex-wrap: nowrap;
	}
}

pre{
	@include font-size(10);
}
