//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: absolute;
	top: 0;
	left: 0;
	transition: $time;
	background-color: transparent;

	.first-hero &{
		background-color: transparent;
		box-shadow: none;
	}

	@media (max-width: $b2){
		z-index: 111;
	}
}

#site-logo{
	display: block;
	height: auto;
	max-width: 100%;
	position: relative;
	margin: 0;
	transition: $time;
	z-index: 1;
	width: 174.46px;
	z-index: 50;

	@media (max-width: $b3){
		width: 129px;
	}

	svg,
	img{
		display: block;
		width: 100%;
	}

	.home &{
		
		svg path{
			fill: white!important;
		}
	}
}

.menu-top-outer{
	padding-top: 44px;
	padding-bottom: 44px;

	body:not(.home) &{
		padding-top: 31px;
		padding-bottom: 31px;

		@media (max-width: $b2){
			padding-top: 27px;
			padding-bottom: 27px;
		}
	}

	@media (max-width: $b2){
		padding-top: 27px;
		padding-bottom: 27px;
	}

	.container{
		max-width: calc(100% - 114px);

		@media (max-width: 1567px){
			max-width: $base-width;
		}
	}

	.col-12{
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: $b2){
			align-items: center;
		}
	}

	.call{
		display: flex;
		flex-wrap: wrap;
		font-family: $alt-font;
		margin-right: 55px;
		margin-bottom: 8px;

		@media (max-width: $b2){
			display: none;
		}

		span{
			margin-right: .6em;
			font-style: italic;
		}

		a{
			@include font-size(24);
			font-weight: 400;

			&:hover,
			&:focus{
				color: $orange;
			}
		}
	}

	.other-side{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.small-menu{
		display: flex;
		flex-wrap: wrap;
		margin-right: 156px;
		margin-top: 8px;

		@media (max-width: $b2){
			display: none;
		}

		li{
			margin: 0 17.5px;

			&:first-of-type{
				margin-left: 0;
			}

			&:last-of-type{
				margin-right: 0;
			}
		}

		a{
			@include font-size(24);
			line-height: 1.5em;
			font-family: $alt-font;
			border-bottom: 1px solid $orange;
			padding: 6px 0;
			text-decoration: none;
			font-weight: 400;
			&.matched,
			&:hover,
			&:focus{
				color: $orange;
			}
		}
	}
}

.menu-bottom-outer{
	position: fixed;
	width: 960px;
	top: 0;
	right: 0%;
	height: 100vh;
	background-color: $green;
	z-index: 111;
	padding: 134px 58px 61px;
	transform: translateX(100%);
	transition: $time;

	@media (max-width: $b3){
		padding: 75px 27px 61px;
	}

	.container{
		height: calc(100vh - 134px - 61px);

		.row{
			height: 100%;
		}
	}

	.menu-open &{
		transform: translateX(0%);
	}

	.col-12:nth-of-type(1){
		align-self: flex-start;
	}

	.col-12:nth-of-type(2){
		align-self: flex-end;

		@media (max-width: $b2){
			padding-left: 0;
			padding-right: 0;
		}
	}

	.links{
		display: flex;
		justify-content: space-between;
		padding-top: 2em;
		width: 711px;

		@media (max-width: $b3){
			margin-left: -10px;
			margin-right: -10px;
			max-width: calc(100% + 10px + 10px);
		}

		h2{
			color: white;
		}

		.link{
			width: calc(50% - 10px);

			@media (max-width: 388px){
				width: calc(50% - 5px);
			}
		}
	}
}

.mob-only.mob-only{

	@media (min-width: 1300px + 1 ){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
	flex-direction: column;

	@media (max-width: $b2){
		overflow: auto;
		height: auto;
		position: absolute;
		top: calc(100% + 13px);
		width: calc(100% + 20px);
		max-width: calc(100% + 20px);
		background-color: $green;
		left: -10px;
		right: -10px;
		display: none;
		z-index: 111;
		transition: $time;
		padding: 0;

		.menu-open &{
			display: block;
		}
	}
}

#mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
#mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
#mainMenu .main-menu .current-menu-parent,
#mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
#mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom){

	> a{

		&:after{
			height: 5px;
		}
	}
}

ul.main-menu.main-menu{
	margin: 0;
	padding: 0;
	@include font-size(0);
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	margin-bottom: 31px;

	li{
		@include font-size(0);
		line-height: 1em;
		margin-bottom: 4px;

		@media (max-width: $b2){

			&:last-of-type{
				border-bottom: none;
			}
		}

		@media (min-width: $b2 + 1){

			&:hover{

				> a{
					color: $orange;
				}
			}
		}

		> a{
			@include font-size(42);
			width: auto;
			color: $white;
			line-height: 1.17em;
			text-decoration: none!important;
			font-weight: 400;
			position: relative;
			font-family: $alt-font;
			display: inline-block;
			position: relative;
			padding: 0 0 0 40px;
			overflow: hidden;
			z-index: 2;
			text-transform: none;

			@media (max-width: 388px){
				@include font-size(36);
			}

			&:after{
				content: '';
				position: absolute;
				top: .56em;
				width: 35px;
				left: 0px;
				background-color: $white;
				height: 1px;
			}

			@media (min-width: $b2 + 1){

				&:hover,
				&:focus{
					color: $orange;
				}
			}
		}
	}
}


#cookie-law-info-bar{
	padding: 20px 25px!important;
	background-color: rgba($green, .9)!important;
	box-shadow: 0 -1px 10px 0 rgba(black,.3)!important;

	@media (max-width: $b3){
		padding: 10px 25px!important;
	}

	.cli-bar-message{
		width: calc(100% - 100px);
		@include font-size(18);

		@media (max-width: $b2){
			@include font-size(10);
		}

		@media (max-width: $b3){
			width: 100%;
			text-align: center;
		}

		a{
			color: white;
			text-decoration: underline;

			&:hover,
			&:focus{
				color: $orange;
			}
		}
	}

	.cli-bar-btn_container{
		margin-left: 0;

		@media (max-width: $b3){
			width: 100%;
			display: flex;
			justify-content: center;
		}

		#wt-cli-accept-all-btn{
			width: 100px;
			@include font-size(18);
			border-radius: 0px;
			background-color: transparent!important;
			padding: 0;
			font-weight: 400;
			background-image: url(../images/cross-c.svg);
			background-repeat: no-repeat;
			background-position: 100% 50%;

			@media (max-width: $b2){
				@include font-size(10);
			}

			&:hover,
			&:focus{
				color: $orange!important;
			}

			@media (max-width: 985px){
				margin: 0;
			}
		}
	}
}
