@font-face {
    font-family: 'Patron';
    src: url('../fonts/Font.woff2') format('woff2'),
        url('../fonts/Font.woff') format('woff'),
        url('../fonts/Font.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'RecifeDisplay';
    src: url('../fonts/RecifeDisplay-Light.woff2') format('woff2'),
        url('../fonts/RecifeDisplay-Light.woff') format('woff'),
        url('../fonts/RecifeDisplay-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'RecifeDisplay';
    src: url('../fonts/RecifeDisplay-LightItalic.woff2') format('woff2'),
        url('../fonts/RecifeDisplay-LightItalic.woff') format('woff'),
        url('../fonts/RecifeDisplay-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'RecifeDisplay';
    src: url('../fonts/RecifeDisplay-Book.woff2') format('woff2'),
        url('../fonts/RecifeDisplay-Book.woff') format('woff'),
        url('../fonts/RecifeDisplay-Book.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'RecifeDisplay';
    src: url('../fonts/RecifeDisplay-BookItalic.woff2') format('woff2'),
        url('../fonts/RecifeDisplay-BookItalic.woff') format('woff'),
        url('../fonts/RecifeDisplay-BookItalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'RecifeDisplay';
    src: url('../fonts/RecifeDisplay-Regular.woff2') format('woff2'),
        url('../fonts/RecifeDisplay-Regular.woff') format('woff'),
        url('../fonts/RecifeDisplay-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

//BREAK POINTS
$b1:				1433px + 20px -  1px; //$base-width + $grid-gutter-width - 1px
$b2:				992px - 1px; //992px - 1px
$b3:				769px - 1px; //769px - 1px
$b4:				576px - 1px; //576px - 1px
$b5:				480px - 1px; //450px

//xs: 0,
//sm: 576px,
//md: 768px,
//lg: 992px,
//xl: $base-width

//!!COLOURS

$red:				#803D42;

$orange-light:		#FEBD85;
$orange:			#FDA961;

$grey-light:		#F8F7F6;
$grey:			    #F1EFED;

$green-light:		#626E6A;
$green:			    #16251F;

$white:				#ffffff;
$black:				#000000;

$colour: 			$green;
$main-bg-colour:	$white;

$font-patr:			'Patron', serif;
//light - 300

$font-reci:			'RecifeDisplay', sans-serif;
//light - 300
//book - 400
//Reg - 500

$main-font:			$font-patr;
$alt-font:			$font-reci;

//!!GRID SETTINGS
$grid-columns:		12 !default;
$grid-gutter-width:	40px !default;

//!!PAGE WIDTH
$base-width:        1433px;

//!!SITE TRANSITIONS
$time:				.25s;

//!!FONT SIZE DIVIDER
$divider:			1.25;
$divider-big:		1.4;

