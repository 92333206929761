//!!BUTTON
button:focus,
.btn:focus{
	outline: none;
}

.btn-con{
	display: flex;
	align-items: center;
	margin: 0 -26px;
	max-width: calc(100% + 26px + 26px);
	flex-wrap: wrap;

	@media (max-width:1024px){
		margin: 0 -7px;
		max-width: calc(100% + 7px + 7px);
	}

	.btn{
		margin: 0 15px 5px;

		@media (max-width: $b2){
			margin: 0 7px 5px;
		}
	}

	&.btn-con-center{
		justify-content: center;
	}

	&.btn-con-left{
		justify-content: flex-start;
	}

	&.btn-con-right{
		justify-content: flex-end;
	}
}

.btn{
	@include inline;
	padding: 16px 16px;
	font-size: 24px!important;
	font-size: 2.4rem!important;
	text-decoration: none!important;
	line-height: 1.5em;
	border: 1px solid $orange;
	color: $green!important;
	font-weight: 400;
	font-style: italic;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	box-shadow: none;
	font-family: $alt-font;
	position: relative;
	border-radius: 0;
	transition: $time;
	background-color: $orange;
	@media only screen and (min-width:1480px){
		min-width: 263px;
	}

	@media (max-width:1024px){
		padding: 7px 5px;
		min-width: 165px;
		font-size: 16px!important;
		font-size: 1.6rem!important;
	}

	@media (max-width: 388px){
		min-width: 50px;
		padding: 7px 5px;
		font-size: 14px!important;
		font-size: 1.4rem!important;
	}

	&:hover,
	&:focus{
		outline: none;
		background-color: $green;
		border-color: $green;
		color: $orange!important;
	}

	&.transparent{
		border-color: $green;
		background-color: transparent;
		color: $green!important;
		min-width: 188px;

		@media (max-width: $b3){
			min-width: 170px;
		}

		&:hover,
		&:focus{
			background-color: $green;
			border-color: $green;
			color: $orange!important;
		}
	}

	&.big{
		padding: 10.5px 45px;
	}

	&.small{
		min-width: 210px;
		padding: 10.5px 10px;
		font-size: 10px!important;
		font-size: 1.0rem!important;
	}
}