footer{
	position: relative;
	background-color: $green;

	.home &{
		display: none;
	}

	.container{
		max-width: 1458px;
	}

	.footer-top-outer{
		padding: 109px 0 0;

		@media (max-width: $b3){
			padding: 49px 0 0;
		}

		.row{
			align-items: flex-start;
		}
		
		*{
			color: $white;
		}

		h1{
			margin-bottom: 1em;

			@media (max-width: 1024px){
				@include font-size(42);
				@include line-height(42,50);
			}
		}

		h4{
			font-style: italic;
			margin-bottom: 0;

			@media (max-width: 1024px){
				@include font-size(26);
				@include line-height(26,28);
			}

			~ address,
			~ a{
				@include font-size(32);
				@include line-height(32,39);
				font-family: $alt-font;

				@media (max-width: 1024px){
					@include font-size(26);
					@include line-height(26,28);
				}
			}
		}

		.col-md-6{
			margin-bottom: 8em;

			@media (max-width: $b3){
				margin-bottom: 2.45em;
			}
		}

		.social-links{
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: -5px;

			@media (max-width: $b3){
				margin-top: -19px;
				margin-bottom: 11px;
				justify-content: flex-start;
			}

			a{
				margin-right: 10px;
				@include font-size(24);

				@media (max-width: $b3){
					@include font-size(37);
				}

				&:hover,
				&:focus{
					color: $orange;

					*{
						color: $orange;
					}
				}
			}
		}
	}

	.footer-middle-outer{
		padding: 0 0 0;

		.icons{
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 15px;

			.icon{
				margin-right: 2.2em;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 20px;

				&:last-of-type{
					margin-right: 0;
				}

				@media (max-width: $b3){
					max-width: 52%;
					margin-right: 1.5em;
				}
			}
		}

		.footer-menu{
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			margin: 0 auto;

			@media (max-width: $b3){
				margin-top: 17px;
			}

			li{
				@include font-size(18);
				line-height: 1.3em;
				margin-bottom: 14px;
				margin-right: 14px;
				position: relative;

				@media (max-width: $b3){
					margin-bottom: 1px;
				}

				&:after{
					content: '';
					position: absolute;
					top: 3px;
					bottom: 3px;
					right: -7px;
					width: 1px;
					background-color: $orange;
					display: block;
				}

				@media (max-width: $b3){
					
					&:first-of-type{

						a{
							padding-left: 0;
						}
					}
				}

				&:last-of-type{

					&:after{
						display: none;
					}
				}

				a{
					@include font-size(18);
					line-height: 1.3em;
					font-weight: 300;
					display: inline-block;
					color: $white;
					text-decoration: none;
					padding: 0;
					

					@media (max-width: $b3){
						@include font-size(16);
					}
					
					&:hover,
					&:focus{
						color: $orange!important;
						text-decoration:none;
					}
				}
			}
		}
	}

	.footer-bottom-outer{
		padding: 30px 0 71px;

		@media (max-width: $b3){
			padding: 38px 0 58px;
		}

		.row{
			align-items: flex-end;
		}

		*{
			color: rgba(white, .34);
		}

		.col-lg-9{

			@media (min-width: $b1 + 1){
				padding-right: 100px;
			}
		}

		.col-lg-3{

			@media (min-width: $b3 + 1){
				display: flex;
				justify-content: flex-end;
			}

			.by{
				color: rgba(white, .15);

				@media (max-width: $b3){
					margin-top: 2.4em;
				}

				*{
					color: rgba(white, .15);
				}

				a{

					&:hover,
					&:focus{
						color: $orange;
					}
				}
			}
		}
	}
}
